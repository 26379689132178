<template>
    <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column">
            <p>{{ $t('widgets.goal.selectGoal') }}</p>
            <p v-if="goalOptionsLoading">
                {{ $t('common.loading') }}
                <font-awesome-icon spin icon="circle-notch" size="lg"/>
            </p>
            <div class="mb-3">
                <v-field name="goal_id" :value="widget.content.goal_id" :formErrors="errors">
                    <p v-if="!goalOptionsLoading && !goalOptions.length"
                       v-html="$t('widgets.goal.noGoals', {link: newGoalLink})"/>
                    <div class="form-check" v-for="goal in goalOptions">
                        <input class="form-check-input" type="radio" :name="goal.name" :id="goal.id"
                               :value="goal.id" v-model="widget.content.goal_id" @input="onSelectGoal(goal.id)">
                        <label class="form-check-label" :for="goal.id">
                            {{ goal.name }}
                        </label>
                    </div>
                </v-field>
            </div>
            <BaseCheckbox v-model="widget.content.confetti_enabled" name="confetti_enabled" :formErrors="errors">
                {{ $t('widgets.goal.confettiEnabled') }}
            </BaseCheckbox>
        </div>
    </div>
</template>

<script setup>
import {useToast} from "vue-toastification";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import BaseCheckbox from "~/components/farbcode-core/Inputs/BaseCheckbox.vue";

const {t} = useI18n()
const toast = useToast()
const {$apiRoute} = useNuxtApp()
const localePath = useLocalePath()

const props = defineProps({
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    modelValue: {
        required: true,
        type: Object,
    },
    submitDisabled: {
        required: true,
        type: Boolean,
    }
})

const emit = defineEmits(['update:modelValue', 'update:submitDisabled'])

const widget = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const submitDisabled = computed({
    get: () => props.submitDisabled,
    set: (value) => emit('update:submitDisabled', value)
})

onMounted(async () => {
    if (useRoute().query?.mode === 'template') {
        // templates must not have a selected goal
        submitDisabled.value = false
    } else {
        await getGoals()
    }

    // select first goal available, if none is selected
    if (!widget.value.content.goal_id && goalOptions.value.length) {
        widget.value.content.goal_id = goalOptions.value[0].id
    }
 })

const newGoalLink = localePath({name: 'organisation-goals-id', params: {id: 0}})
const goalOptionsLoading = ref(false)
const goalOptions = ref([])

async function getGoals() {
    goalOptionsLoading.value = true

    await $larafetch($apiRoute('spa.goals.index'), {
        method: 'GET',
        query: {
            mode: 'select-widget'
        }
    }).then(response => {
        goalOptions.value = response
    }).catch(errors => {
        console.log(errors)
        goalOptions.value = []
    }).finally(() => {
        goalOptionsLoading.value = false
    })
}

// watch for changes in the goal_id to enable/disable the submit button
watch(() => widget.value.content.goal_id, () => {
    submitDisabled.value = !widget.value.content.goal_id
}, {immediate: true})

function onSelectGoal(goalId) {
    widget.value.content.goal_id = goalId
}
</script>

